@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@400;600&family=Work+Sans:wght@400;500&display=swap');

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
		"Droid Sans", "Helvetica Neue", sans-serif, ;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@font-face {
	font-family: "Inter";
	font-weight: 400;
	src: url(./Assets/Fonts/Inter-Regular.ttf);
}


@font-face {
	font-family: "Inter";
	font-weight: 500;
	src: url(./Assets/Fonts/Inter-Medium.ttf);
}

@font-face {
	font-family: "Inter";
	font-weight: 600;
	src: url(./Assets/Fonts/Inter-SemiBold.ttf);
}

@font-face {
	font-family: "Comfortaa";
	font-weight: 400;
	src: url(./Assets/Fonts/Comfortaa-Regular.ttf);
}
@font-face {
	font-family: "Comfortaa";
	font-weight: 500;
	src: url(./Assets/Fonts/Comfortaa-Medium.ttf);
}
@font-face {
	font-family: "Comfortaa";
	font-weight: 600;
	src: url(./Assets/Fonts/Comfortaa-SemiBold.ttf);
}


@font-face {
	font-family: "Work Sans";
	font-weight: 400;
	src: url(./Assets/Fonts/WorkSans-Regular.ttf);
}
@font-face {
	font-family: "Work Sans";
	font-weight: 500;
	src: url(./Assets/Fonts/WorkSans-Medium.ttf);
}
@font-face {
	font-family: "Work Sans";
	font-weight: 600;
	src: url(./Assets/Fonts/WorkSans-SemiBold.ttf);
}


.main-logo{
	height : 45px;
	object-fit:contain;
}